import React, { useState, useEffect, useContext } from 'react';
import {
  Card, CardMedia, Link, CardTitle, Button, CardBody, Typography
} from '@one-thd/sui-atomic-components';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import { Info, Close } from '~/@one-thd/sui-icons';

import { CARD_IMAGE, DEFAULT_PREQUAL_URL } from '../utils/constants';
import { clickEvent, impressionEvent } from '../utils/publisher';
import { getPrequalOfferLink, loadEstimatorScript } from '../utils/payment-estimator-utils';
import { fetchDeclinePrequalOffer, getPrequalEligibility } from '../utils/api';
import { getDynamicOffer } from '../utils/general';
import PrequalInfoDrawer from '../common/prequal-info-drawer.component';

const PLCCPreQualified = (props) => {
  const { showPrequal, userDetails, orderAmount, prequalInvNumber } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const [paymentEstimatorLoaded, setPaymentEstimatorLoaded] = useState(true);
  const experienceContext = useContext(ExperienceContext);
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.5.0';
  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';
  const [prequalInvitationNumber, setPrequalInvitationNumber] = useState(null || prequalInvNumber);
  const [prequalLink, setPrequalLink] = useState(
    DEFAULT_PREQUAL_URL
  );

  const checkPrequalEligibility = () => {
    const prequalRequestObject = {
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      addrLine1: userDetails?.line1,
      city: userDetails?.city,
      state: userDetails?.stateCode,
      zipCode: userDetails?.zipCode,
      emailAddress: userDetails?.email
    };
    getPrequalEligibility(channel, prequalRequestObject).then(async (response) => {
      const json = await response.json();
      if (json?.invitationNo && json?.status === 'Q') {
        setPrequalInvitationNumber(json?.invitationNo);
        showPrequal(true);
      }
    }).catch((error) => {
      console.error('Exception occured in prequal', error);
    });
  };

  const CardImage = () => {
    return (
      <CardMedia
        className="sui-object-fill sui-w-full"
        src={CARD_IMAGE.PLCN_HOMEDEPOT}
        alt="Credit Card Icon"
        aspect="wide"
      />
    );
  };

  const analyticsInteraction = () => {
    clickEvent('plcc-check prequal', 'card');
  };

  const calculateTotalPrice = () => {
    const discountAmount = getDynamicOffer();
    const offerPrice = Number(orderAmount) > 25 ? Number(orderAmount) - discountAmount : Number(orderAmount);
    return offerPrice?.toFixed(2);
  };

  const onClickNoThanks = () => {
    fetchDeclinePrequalOffer(prequalInvitationNumber, isMobile);
    showPrequal(false);
  };

  useEffect(() => {
    if (prequalInvitationNumber) {
      loadEstimatorScript(
        paymentEstimatorVersion,
        () => {
          setPrequalLink(getPrequalOfferLink(isMobile, 'homepage', prequalInvitationNumber));
          setPaymentEstimatorLoaded(true);
        },
        isMobile
      );
      impressionEvent('plcc-check prequal', 'card');
    }
  }, [prequalInvitationNumber]);

  useEffect(() => {
    if (userDetails?.firstName
      && userDetails?.lastName
      && userDetails?.zipCode
      && userDetails?.city
      && userDetails?.stateCode
      && userDetails?.email
      && !prequalInvitationNumber) {
      checkPrequalEligibility();
    }
  }, [userDetails]);

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  if (!prequalInvitationNumber) return <></>;

  return (
    <>
      <Card orientation="horizontal" className="sui-relative">
        <div className="sui-w-32">
          <CardImage className="sui-mt-3" />
          <div className="sui-mt-2">
            <Button
              variant="secondary"
              href={prequalLink}
              target="_blank"
              onClick={analyticsInteraction}
              fullWidth
              data-testid="prequal-link"
            >Apply Now
            </Button>
          </div>
        </div>
        <div className="sui-w-11/12">
          <CardTitle header={(
            <><b>{userDetails?.firstName}, You&lsquo;re Pre-Qualified</b>
              <span
                aria-hidden="true"
                data-testid="info-link"
                onClick={() => setShowDrawer(true)}
                className="sui-pl-1 sui-cursor-pointer sui-relative"
              >
                <Info color="info" size="small" />
              </span>
            </>
          )}
          />
          <CardBody>
            <Typography className="sui-mt-2" data-testid="prequal-text">
              Pay <b>${calculateTotalPrice()}</b> after <b>${getDynamicOffer()} OFF</b> your total
              qualifying purchase upon opening a new card.{' '}
              <Link data-testid="close-button" onClick={onClickNoThanks}>
                No Thanks
              </Link>
            </Typography>
          </CardBody>
          <div className="sui-mt-3 sui-border-1 sui-border-solid
                    sui-border-button-inactive sui-p-2 sui-text-xs"
          >
            You can choose to stop receiving “prescreened” offers of credit from this and other companies
            by calling toll-free 1-888-5-OPTOUT (1-888-567-8688). See PRESCREEN & OPT-OUT NOTICE
            in the Terms and Conditions for more information about prescreened offers.
          </div>
        </div>
      </Card>

      <PrequalInfoDrawer
        closeDrawer={closeDrawer}
        open={showDrawer}
        paymentEstimatorLoaded={paymentEstimatorLoaded}
        firstName={userDetails?.firstName}
        isMobile={isMobile}
        applynowURL={prequalLink}
        price={orderAmount}
      />
    </>
  );
};

PLCCPreQualified.propTypes = {
  showPrequal: PropTypes.func,
  orderAmount: PropTypes.number,
  prequalInvNumber: PropTypes.number,
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    line1: PropTypes.string,
    city: PropTypes.string,
    stateCode: PropTypes.string,
    zipCode: PropTypes.string,
    email: PropTypes.string
  }).isRequired
};
PLCCPreQualified.defaultProps = {
  showPrequal: null,
  prequalInvNumber: null,
  orderAmount: 0.0
};

export { PLCCPreQualified };
