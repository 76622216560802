import React, { useState, useEffect, useContext } from 'react';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import {
  Card,
  CardTitle,
  Typography,
  SkeletonLine,
  SkeletonBlock
} from '@one-thd/sui-atomic-components';
import PropTypes, { string } from 'prop-types';
import AccountSnapshot from './account-snapshot/AccountSnapshot.component';
import LinkAccounts from './link-account/LinkAccounts.component';
import Prequal from './prequal/Prequal.component';
import ApplyNow from './apply-now/ApplyNow.component';
import Error from './error/Error.component';
import ProductSpotlight from './product-spotlight/product-spotlight.component';
import SsoRedirectForm from '../common/SsoRedirectForm.component';
import AccountSelectorDrawer from '../common/account-selector-drawer.component';
import {
  ssoExists,
  getDefaultCard,
  unauthorizedError,
  accountUnlinkedDueToInactivity,
  plccCardSaved,
  defaultCard401CitiError,
  isErrorResponse,
  isNotAuthenticated,
} from '../utils/general';
import { DEFAULT_URL } from '../utils/constants';
import {
  fetchPlccData,
  getSsoUrl
} from '../utils/api';
import { newRelic } from '../utils/new-relic';
import ApplyNowB2B from './apply-now-b2b/ApplyNowB2B.component';

export const PLCCPod = (props) => {
  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const { storeId } = useStore();
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.2.0';
  const {
    urlTmxId, showOnlyApplyNow, id, mockSnapshotResponse, isB2B
  } = props;
  const [showPrequal, setShowPrequal] = useState(true);
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userInStore, setUserInStore] = useState(false);
  const [savedConsumerButNoSso, setSavedConsumerButNoSso] = useState(false);
  const [savedCommercialButNoSso, setSavedCommercialButNoSso] = useState(false);
  const [accountSnapshotData, setAccountSnapshotData] = useState(false);
  const [getSsoUrlResponse, setGetSsoUrlResponse] = useState(false);
  const [prequalInvitationNumber, setPrequalInvitationNumber] = useState(null);
  const [showAccountSelectorDrawer, setShowAccountSelectorDrawer] = useState(
    false
  );
  const [productData, setProductData] = useState(null);

  const showRelinkAccountsUi = (defaultCard) => {
    setShowPrequal(false);
    setSavedConsumerButNoSso(defaultCard.accountType === 'PLCN_HOMEDEPOT');
    setSavedCommercialButNoSso(
      defaultCard.accountType === 'PLCR_HOMEDEPOT' || defaultCard.accountType === 'PLNP_HOMEDEPOT'
    );
    setIsLoading(false);
  };

  const showRelinkAfterInactivityUi = () => {
    setShowPrequal(false);
    setSavedConsumerButNoSso(true);
    setSavedCommercialButNoSso(true);
    setIsLoading(false);
  };

  const showSnapshotErrorUi = () => {
    setShowPrequal(false);
    setShowErrorComponent(true);
    setIsLoading(false);
  };

  const showAccountSnapshotUi = (defaultCard) => {
    setAccountSnapshotData(defaultCard);
    setShowPrequal(false);
    setIsLoading(false);
  };

  const showConsumerCardSavedUi = (data) => {
    setShowPrequal(false);
    setSavedConsumerButNoSso(true);
    setSavedCommercialButNoSso(
      plccCardSaved(data, 'PLNP_HOMEDEPOT')
      || plccCardSaved(data, 'PLCR_HOMEDEPOT')
    );
    setIsLoading(false);
  };

  const showCommercialCardSavedUi = () => {
    setShowPrequal(false);
    setSavedCommercialButNoSso(true);
    setIsLoading(false);
  };

  const showPrequalUi = (prequalInvitation) => {
    setPrequalInvitationNumber(prequalInvitation);
    setShowPrequal(true);
    setIsLoading(false);
  };

  const showProductSpotlightUi = (product) => {
    setProductData(product);
    setShowPrequal(false);
    setIsLoading(false);
  };

  const showApplyNowUi = () => {
    setShowPrequal(false);
    setIsLoading(false);
  };

  const redirectToCitiBauLogin = (cardType) => {
    window.location.assign(DEFAULT_URL[cardType]);
  };

  const redirectToThdMyAccount = () => {
    window.location.assign(`${window.location.origin}/account/view/thdcreditcard`);
  };

  const clickHandlerRedirectToCiti = async (
    processIndicator = 'dashboard',
    siteId = 'PLCN_HOMEDEPOT'
  ) => {
    setIsLoading(true);
    try {
      const response = await getSsoUrl(channel === 'mobile', siteId);
      const json = await response.json();
      newRelic.logPlccApiResponse(json);
      if (json.errors) {
        setShowErrorComponent(true);
        setIsLoading(false);
      } else if (json?.urlType === 'SSOLOGIN') {
        json.processIndicator = processIndicator;
        setGetSsoUrlResponse(json);
        setIsLoading(false);
      } else if (
        json?.urlType === 'AUTHORIZE' || json?.urlType === 'DEFAULT'
      ) {
        window.location.assign(json.url);
      } else {
        setShowErrorComponent(true);
        setIsLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setShowErrorComponent(true);
      setIsLoading(false);
    }
  };

  const clickHandlerLinkAccounts = () => {
    if (savedCommercialButNoSso) {
      setShowAccountSelectorDrawer(true);
    } else {
      clickHandlerRedirectToCiti();
    }
  };

  const hideAccountSelectorDrawer = () => {
    setShowAccountSelectorDrawer(false);
  };

  async function getPlccData() {
    // Check if in store
    if (!mockSnapshotResponse && window?.Localizer?.status?.isUserInStore() === true) {
      setUserInStore(true);
      setIsLoading(false);
      return;
    }
    // Check Auth Status before proceeding
    if (!mockSnapshotResponse && isNotAuthenticated()) {
      showApplyNowUi();
      return;
    }

    try {
      let json;
      if (mockSnapshotResponse) {
        setShowErrorComponent(false);
        setSavedConsumerButNoSso(false);
        setSavedCommercialButNoSso(false);
        setAccountSnapshotData(false);
        setProductData(null);
        json = mockSnapshotResponse;
      } else {
        const apiCallStartTime = performance.now();
        const response = await fetchPlccData(channel, storeId, urlTmxId);
        const apiCallEndTime = performance.now();
        json = await response.json();
        const apiCallDurationInSeconds = parseFloat(
          // eslint-disable-next-line no-new-wrappers
          new String((apiCallEndTime - apiCallStartTime) / 1000).substring(0, 6)
        );
        newRelic.logPlccApiResponse(json, response.status, apiCallDurationInSeconds);
      }
      if (ssoExists(json)) {

        const defaultCard = getDefaultCard(json.accounts);
        if (defaultCard401CitiError(defaultCard)) {
          showRelinkAccountsUi(defaultCard);
        } else if (defaultCard.errors) {
          showSnapshotErrorUi();
        } else {
          showAccountSnapshotUi(defaultCard);
        }
      } else if (unauthorizedError(json) || isErrorResponse(json)) {
        showApplyNowUi();
      } else if (accountUnlinkedDueToInactivity(json)) {
        showRelinkAfterInactivityUi();
      } else if (plccCardSaved(json, 'PLCN_HOMEDEPOT')) {
        showConsumerCardSavedUi(json);
      } else if (
        plccCardSaved(json, 'PLNP_HOMEDEPOT')
        || plccCardSaved(json, 'PLCR_HOMEDEPOT')
      ) {
        showCommercialCardSavedUi();
      } else if (json.prequalInvitation) {
        showPrequalUi(json.prequalInvitation);
      } else if (json.product) {
        showProductSpotlightUi(json.product);
      } else {
        showApplyNowUi();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showApplyNowUi();
    }
  }

  useEffect(() => {
    getPlccData();
  }, [mockSnapshotResponse]);

  const getApplyNowComponent = () => {
    if (isB2B) {
      return <ApplyNowB2B paymentEstimatorVersion={paymentEstimatorVersion} isMobile={channel === 'mobile'} />;
    }
    return <ApplyNow paymentEstimatorVersion={paymentEstimatorVersion} isMobile={channel === 'mobile'} />;

  };

  const plccCardContent = () => {
    if (userInStore || showOnlyApplyNow) {
      return getApplyNowComponent();
    }

    if (getSsoUrlResponse) {
      return <SsoRedirectForm ssoResponse={getSsoUrlResponse} />;
    }

    if (showPrequal) {
      return (
        <Prequal
          prequalInvitationNumber={prequalInvitationNumber}
          paymentEstimatorVersion={paymentEstimatorVersion}
          showApplyNow={showApplyNowUi}
          isMobile={channel === 'mobile'}
        />
      );
    }

    if (showErrorComponent) {
      return <Error redirectToCiti={redirectToCitiBauLogin} />;
    }

    if (accountSnapshotData) {
      return (
        <AccountSnapshot
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...accountSnapshotData}
          redirectToCiti={clickHandlerRedirectToCiti}
          redirectToThdMyAccount={redirectToThdMyAccount}
        />
      );
    }

    if (savedConsumerButNoSso || savedCommercialButNoSso) {
      return (
        <>
          {showAccountSelectorDrawer && (
            <AccountSelectorDrawer
              payAndManage={false}
              consumerLinked={false}
              crcLinked={false}
              proxLinked={false}
              closeDrawer={hideAccountSelectorDrawer}
              redirectToCiti={clickHandlerRedirectToCiti}
            />
          )}
          <LinkAccounts
            linkAccounts={clickHandlerLinkAccounts}
            consumerCard={savedConsumerButNoSso}
            commercialCard={savedCommercialButNoSso}
            isMobile={channel === 'mobile'}
          />
        </>
      );
    }

    if (productData) {
      return (
        <ProductSpotlight
          product={productData}
          isMobile={channel === 'mobile'}
          paymentEstimatorVersion={paymentEstimatorVersion}
        />
      );
    }

    return getApplyNowComponent();
  };

  if (isLoading) {
    return (
      <div data-component="PLCCPodPlaceholder">
        <Card className="sui-w-full sui-h-full">
          <CardTitle
            disableTypography
            header={(
              <Typography variant="h3">Home Depot Credit Cards</Typography>
            )}
          />
          <SkeletonBlock aspect="wide" />
          <SkeletonLine variant="multi" numberOfLines={6} />
        </Card>
      </div>
    );
  }

  return (
    <div
      data-component="PLCCPod"
      className="sui-paper sui-paper-outlined sui-rounded-base
      sui-flex sui-w-full sui-h-full sui-overflow-hidden sui-p-4 sui-gap-4"
    >
      {plccCardContent()}
    </div>
  );
};

PLCCPod.propTypes = {
  urlTmxId: string,
  showOnlyApplyNow: PropTypes.bool,
  id: string,
  mockSnapshotResponse: PropTypes.object, // eslint-disable-line
  isB2B: PropTypes.bool,
};

PLCCPod.defaultProps = {
  urlTmxId: null,
  showOnlyApplyNow: false,
  id: '',
  mockSnapshotResponse: null,
  isB2B: false,
};
